

































import { AllocationDTO } from '@/api-client'
import { parseUtcDate } from '@/modules/date'
import { format } from 'date-fns'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AllocationResponse extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  allocation!: AllocationDTO

  get textAlignment() {
    return this.$vuetify.breakpoint.smAndUp ? 'center' : 'start'
  }

  get icon() {
    return this.allocation.status === 1 ? '$crossCircle' : '$checkInnerCircle'
  }

  get iconColor() {
    return this.allocation.status === 1 ? 'error' : 'accent-aquamarine'
  }

  get title() {
    return this.allocation.status === 1 ? 'Rejected' : 'Accepted'
  }

  get date() {
    return format(
      parseUtcDate(
        this.allocation.acceptedDateTime ?? this.allocation.rejectedDateTime!,
      ),
      'dd/LL/yyyy, HH:mm',
    )
  }
}
