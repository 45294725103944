




















import { ReleaseDTO } from '@/api-client'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ReleaseDocuments extends Vue {
  @Prop({ required: true }) release!: ReleaseDTO

  get visible() {
    return this.release?.documents?.length
  }
}
