<template>
  <v-card flat color="white" class="pa-12">
    <v-row no-gutters>
      <v-col xs="12" sm="6" class="pb-xs-10">
        <div class="text-h4">
          Want to know more about how the {{ PRODUCT_NAME }} algorithm works?
        </div>
        <v-btn
          color="primary"
          class="mt-10"
          :href="`${MARKETING_URL}/algorithm`"
          target="_blank"
          :block="$vuetify.breakpoint.xs"
          >Find out more</v-btn
        >
      </v-col>
      <v-col xs="12" sm="6" class="pt-12 pt-sm-0">
        <v-img class="float-right" width="274px" src="/img/algorithm.svg" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script lanng="ts">
import { Vue, Component } from 'vue-property-decorator'
import { MARKETING_URL, PRODUCT_NAME } from '@/modules/config'

@Component
export default class FindOutMore extends Vue {
  MARKETING_URL = MARKETING_URL
  PRODUCT_NAME = PRODUCT_NAME
}
</script>
