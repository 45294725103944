















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import FileUpload from '../../../components/inputs/FileUpload.vue'
import ProgressCentered from '@/components/ProgressCentered.vue'
import AcceptTermsAndConditions from '@/components/inputs/AcceptTermsAndConditions.vue'
import ApplicationActionConfirmation from '@/components/ApplicationActionConfirmation.vue'
import AddressForm from '@/components/forms/AddressForm.vue'
import ContactForm from '@/components/forms/ContactForm.vue'
import ApplicationActions from '@/components/ApplicationActions.vue'
import FindOutMore from '@/components/FindOutMore.vue'
import AllocationResponse from '@/components/AllocationResponse.vue'
import ValidationMessages from '@/components/ValidationMessages.vue'
import ConfirmationCheckbox from '@/components/inputs/ConfirmationCheckbox.vue'
import BackButton from '@/components/BackButton.vue'
import ApplicationCloseDate from '@/components/ApplicationCloseDate.vue'
import ReleaseDocuments from '@/components/ReleaseDocuments.vue'
import ApplicationDetails from '@/components/ApplicationDetails.vue'
import LotPreferenceForm from '@/components/forms/LotPreferenceForm.vue'
import InfoCard from '@/components/InfoCard.vue'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { validate, ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  AllocationDTO,
  AllocationType,
  ApplicationDTO,
  ContactDTO,
  ParticularsDTO,
  ReleaseSigningTimeDTO,
} from '@/api-client'
import { AxiosResponse } from 'axios'
import { formatSigningTime, parseUtcDate } from '@/modules/date'
import { addDays, isPast } from 'date-fns'
import { identificationMethodsList } from '@/types/IdentificationMethod'
import { formatAddress, formatAUD } from '@/modules/stringUtils'
import ApplicationStep from '@/types/ApplicationStep'
import { MARKETING_URL, states } from '@/modules/config'
import { lookup } from 'mime-types'
import { FinanceTimeframeDescription } from '@/types/FinanceTimeframe'
import { ApiErrorCode } from '@/types/ApiError'

type ConveyancerFieldsOfParticularsDTO =
  | 'conveyancerName'
  | 'conveyancerEmailAddress'
  | 'conveyancerPhoneNumber'
  | 'conveyancerAddress'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    InfoCard,
    LotPreferenceForm,
    ApplicationDetails,
    ReleaseDocuments,
    ApplicationCloseDate,
    BackButton,
    AcceptTermsAndConditions,
    ConfirmationCheckbox,
    ValidationMessages,
    AllocationResponse,
    FindOutMore,
    ApplicationActions,
    AddressForm,
    ContactForm,
    ApplicationActionConfirmation,
    ProgressCentered,
    FileUpload,
  },
  head: {
    title() {
      return {
        inner: 'Application',
      }
    },
  },
})
export default class Application extends Vue {
  ApplicationStep = ApplicationStep
  MARKETING_URL = MARKETING_URL

  loading = true
  application: ApplicationDTO | null = null
  signingTimes: ReleaseSigningTimeDTO[] | null = null
  isConfirming = false

  hasAcceptedTerms = false

  identificationMethods = identificationMethodsList

  particulars: Omit<ParticularsDTO, ConveyancerFieldsOfParticularsDTO> = {
    isPurchaserCompanyOrTrust: false,
    entityName: '',
    entityAbn: '',
    givenName: '',
    familyName: '',
    emailAddress: '',
    phoneNumber: '',
    address: {
      line1: '',
      suburb: '',
      state: '',
      postalCode: '',
      country: 'Australia',
    },
    otherContacts: [],
    lenderName: '',
    loanAmount: (null as unknown) as number,
    isSubjectToFinance: true,
    isAustralianResident: true,
    identificationType: this.identificationMethods[0],
    identificationNumber: '',
    identificationAuthority: '',
  }

  conveyancer: Pick<ParticularsDTO, ConveyancerFieldsOfParticularsDTO> = {
    conveyancerName: '',
    conveyancerEmailAddress: '',
    conveyancerPhoneNumber: '',
    conveyancerAddress: {
      line1: '',
      suburb: '',
      state: '',
      postalCode: '',
      country: 'Australia',
    },
  }

  hasConveyancer = true

  additionalQuestionAnswers: string[] = []

  signingTime = ''

  depositProof: File | null = null
  proofSource = ''

  acceptErrors: string[] = []
  rejectErrors: string[] = []
  particularsErrors: string[] = []
  signingTimeErrors: string[] = []
  depositErrors: string[] = []

  get maximumPurchasers() {
    return this.particulars.isPurchaserCompanyOrTrust ? 2 : 5
  }

  get contactLabel() {
    return this.particulars.isPurchaserCompanyOrTrust
      ? 'Authorised Signatory'
      : 'Purchaser'
  }

  get allocatedLots() {
    const id = this.application?.allocation?.lotId
    return id ? [this.application?.release?.lots.find(l => l.id === id)] : []
  }

  get transformedPreferences() {
    if (!this.application) return []
    const preferences: { [id: string]: number } = {}
    for (const preference of this.application?.preferences) {
      preferences[preference.lotId] = preference.order
    }
    return preferences
  }

  get formattedFinanceTimeframe() {
    const timeframe = this.application?.release?.signingDetails
      ?.financeTimeframe
    return timeframe && timeframe > 0
      ? FinanceTimeframeDescription[timeframe]
      : 0
  }

  get states() {
    return states.map(s => s.postal)
  }

  get depositAmount() {
    const amount = this.release?.signingDetails?.depositAmount
    if (amount) return formatAUD(amount, 1)
    return null
  }

  get applicationsCloseDate() {
    if (!this.release?.details?.applicationsCloseDate) {
      return new Date()
    } else
      return addDays(
        parseUtcDate(this.release.details.applicationsCloseDate),
        1,
      )
  }

  get acceptanceCloseDate() {
    return this.application?.allocation?.acceptanceEndDateTime
  }

  get isOnDemandPurchase() {
    return (
      this.application?.allocation?.allocationType === AllocationType.OnDemand
    )
  }

  get isAcceptanceClosed() {
    const closeDate = this.application?.release?.details?.applicationsCloseDate
    if (!closeDate) return true
    const parsedAcceptanceDate = parseUtcDate(this.acceptanceCloseDate!)
    if (isPast(parsedAcceptanceDate)) return true
    return false
  }

  get transformedSigningTimes() {
    if (!this.signingTimes) return []
    return this.signingTimes
      ?.filter(t => t.dateFrom && t.dateTo && !t.isAssigned)
      .map(t => {
        return {
          id: t.id,
          formattedDate: formatSigningTime(
            parseUtcDate(t.dateFrom!),
            parseUtcDate(t.dateTo!),
          ),
        }
      })
  }

  get actions() {
    if (this.isOnDemandPurchase)
      return ['particularsComplete', 'depositComplete']

    return ['particularsComplete', 'signingTimeComplete', 'depositComplete']
  }

  get numActionsTotal() {
    return this.actions.length
  }

  get numActionsComplete() {
    if (!this.application?.allocation) return 0

    return (this.actions as (keyof AllocationDTO)[]).reduce(
      (acc, key) => acc + (this.application!.allocation![key] ? 1 : 0),
      0,
    )
  }

  get windowModel() {
    return this.$route.query.step
      ? `${this.$route.query.step}${this.isConfirming ? '-confirm' : ''}`
      : this.isConfirming
      ? 'confirm'
      : ApplicationStep.OVERVIEW.toString()
  }

  get particularsConfirmationTabularData() {
    const returnData: Record<string, string[][]> = {}

    // Add Business, if there is one
    if (this.particulars.isPurchaserCompanyOrTrust) {
      returnData['Purchaser'] = [
        ['Company or trust name', this.particulars.entityName!],
        ['Company or trust ABN', this.particulars.entityAbn!],
        [
          'Registered place of business',
          formatAddress(this.particulars.address),
        ],
      ]
    }

    // Add contacts
    ;[this.particulars, ...this.particulars.otherContacts!].reduce<
      Record<string, string[][]>
    >((all, contact: ContactDTO | ParticularsDTO, index) => {
      all[`${this.contactLabel} ${index + 1}`] = [
        ['Name', `${contact.givenName} ${contact.familyName}`],
        ['Email', contact.emailAddress!],
        ['Phone number', contact.phoneNumber!],
        ['Australian resident', contact.isAustralianResident ? 'Yes' : 'No'],
        ['Identification type', contact.identificationType],
        [`${contact.identificationType} number`, contact.identificationNumber],
      ]

      const contactFromParticulars = contact as ParticularsDTO
      if (
        contactFromParticulars.address &&
        !this.particulars.isPurchaserCompanyOrTrust
      ) {
        all[`${this.contactLabel} ${index + 1}`].push([
          'Current address',
          formatAddress(contactFromParticulars.address),
        ])
      }
      return all
    }, returnData)

    return {
      ...returnData,
      Lender: [
        [
          'Subject to finance',
          this.particulars.isSubjectToFinance ? 'Yes' : 'No',
        ],
      ].concat(
        this.particulars.isSubjectToFinance
          ? [
              ['Financial Institution', this.particulars.lenderName!],
              ['Loan Amount', `$${this.particulars.loanAmount}`],
            ]
          : [],
      ),
      'Legal practitioner / conveyancer': this.hasConveyancer
        ? [
            ['Practice name', this.conveyancer.conveyancerName],
            [
              'Address',
              this.conveyancer.conveyancerAddress
                ? formatAddress(this.conveyancer.conveyancerAddress)
                : '',
            ],
            ['Email', this.conveyancer.conveyancerEmailAddress],
            ['Phone', this.conveyancer.conveyancerPhoneNumber],
          ]
        : [['N/A']],
    }
  }

  get signingTimeConfirmationTabularData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const details: { [key: string]: any[] } = {
      'Site address': [
        [this.application?.release?.signingDetails?.signingLocation],
      ],
    }

    if (this.application?.release?.signingDetails?.signingInstructions) {
      details['Contract appointment instructions'] = [
        [this.application?.release?.signingDetails?.signingInstructions],
      ]
    }
    details['Contract appointment time'] = [
      [
        this.transformedSigningTimes.find(t => t.id === this.signingTime)
          ?.formattedDate,
      ],
    ]
    return details
  }

  get depositConfirmationTabularData() {
    return {
      'Payment details': [
        [
          'Account name',
          this.release?.signingDetails?.depositAccountName,
          '$copy',
        ],
        ['BSB', this.release?.signingDetails?.depositAccountBsb, '$copy'],
        [
          'Account number',
          this.release?.signingDetails?.depositAccountNumber,
          '$copy',
        ],
        [
          'Amount due',
          this.depositAmount,
          `$copy${this.release?.signingDetails?.depositAmount}`,
        ],
        [
          'Reference code',
          this.application?.allocation?.depositDescription,
          `$copy${this.application?.allocation?.depositDescription}`,
        ],
      ],
    }
  }

  get release() {
    return this.application?.release!
  }

  @Watch('particulars.isPurchaserCompanyOrTrust')
  onIsPurchaserCompanyOrTrustChange() {
    // trim down the OtherContacts array length if it is now larger than the max size allowed
    if (this.particulars.otherContacts!.length + 1 > this.maximumPurchasers) {
      this.particulars = {
        ...this.particulars,
        otherContacts: this.particulars.otherContacts?.slice(
          0,
          this.maximumPurchasers - 1,
        ),
      }
    }
  }

  addPurchaser() {
    this.particulars.otherContacts?.push({
      givenName: '',
      familyName: '',
      emailAddress: '',
      phoneNumber: '',
      isAustralianResident: true,
      identificationType: this.identificationMethods[0],
      identificationNumber: '',
      identificationAuthority: '',
    })
  }

  removePurchaser() {
    const otherContacts = [...this.particulars.otherContacts!]
    otherContacts.pop()
    // need to reassign the top level object so that change detection is triggered.
    this.particulars = {
      ...this.particulars,
      otherContacts,
    }
  }

  onContactChanged(contact: ContactDTO, index: number) {
    const contacts = [...this.particulars.otherContacts!]
    contacts[index] = contact
    // need to reassign the top level object so that change detection is triggered.
    this.particulars = {
      ...this.particulars,
      otherContacts: contacts,
    }
  }

  onDepositChange(newFile: File | null) {
    if (newFile) {
      if (lookup(newFile.name) === 'application/pdf') {
        this.proofSource = ''
        return
      }

      const reader = new FileReader()
      reader.onload = () => {
        this.proofSource = reader.result as string
      }
      reader.readAsDataURL(newFile)
    }
  }

  async acceptAllocation() {
    this.loading = true
    await this.$api.application
      .v1ApplicationsApplicationIdAcceptPost(
        this.application?.id!,
        this.application?.key,
      )
      .then(() => {
        // For some reason AllocationResponse isn't displaying after updating
        // application. This is the yucky fix
        this.goToApplicationStep()
        location.reload()
      })
      .catch(() => {
        this.acceptErrors = ['An error occurred.']
      })
  }

  async rejectAllocation() {
    this.loading = true
    await this.$api.application
      .v1ApplicationsApplicationIdRejectPost(
        this.application?.id!,
        this.application?.key,
      )
      .then(() => {
        // For some reason AllocationResponse isn't displaying after updating
        // application. This is the yucky fix
        this.goToApplicationStep()
        location.reload()
      })
      .catch(() => {
        this.rejectErrors = ['An error occurred.']
      })
  }

  async submitParticulars() {
    this.loading = true
    await Promise.all([
      this.$api.application.v1ApplicationsApplicationIdParticularsPost(
        this.application?.id!,
        this.application?.key,
        {
          ...this.particulars,
          ...(this.hasConveyancer ? this.conveyancer : {}),
        },
      ),
      this.$api.application.v1ApplicationsApplicationIdParticularAnswersPost(
        this.application?.id!,
        this.application?.key,
        this.application!.release!.particularQuestions.map((q, i) => ({
          allocationId: this.application?.allocation?.id,
          particularQuestionId: q.id!,
          answer: this.additionalQuestionAnswers[i],
        })).filter(a => !!a.answer),
      ),
    ])
      .then(() => {
        this.application!.allocation!.particularsComplete = true
        this.isConfirming = false
        this.goToApplicationStep()
      })
      .catch(() => {
        this.particularsErrors = ['An error occurred.']
      })
    this.loading = false
  }

  @Watch('signingTime')
  clearSigningTimeErrors() {
    this.signingTimeErrors = []
  }

  async submitSigningTime() {
    this.loading = true
    await this.$api.application
      .v1ApplicationsApplicationIdSigningTimePost(
        this.application?.id!,
        this.application?.key,
        {
          id: this.signingTime,
        },
      )
      .then(() => {
        this.application!.allocation!.signingTimeComplete = true
        this.isConfirming = false
        this.goToApplicationStep()
      })
      .catch(err => {
        if (err.response?.data?.code === ApiErrorCode.SIGNING_TIME_NOT_FOUND) {
          this.loadSigningTimes()
          this.signingTimeErrors = [
            'The selected signing time is now unavailable. Please go back and select a different one.',
          ]
        } else {
          this.signingTimeErrors = ['An error occurred.']
        }
      })

    this.loading = false
  }

  async submitDeposit() {
    this.loading = true
    try {
      const {
        data,
      } = await this.$api.application.v1ApplicationsApplicationIdDepositPost(
        this.application?.id!,
        this.application?.key,
        {
          name: this.depositProof!.name,
          displayName: this.depositProof!.name,
        },
      )

      await this.$axios.put(data.url!, this.depositProof)
      this.application!.allocation!.depositComplete = true
      this.application!.allocation!.depositDescription = data.displayName
      this.isConfirming = false
      this.goToApplicationStep()
    } catch (e) {
      this.depositErrors = ['An error occurred']
    }

    this.loading = false
  }

  goToApplicationStep(step?: ApplicationStep) {
    const basePath = `/applications/${this.$route.params.id}`
    const newRoute = {
      path: basePath,
      query: {
        key: this.$route.query.key,
        step: step !== undefined ? step.toString() : undefined,
      },
    }
    this.$router.push(newRoute).catch(() => {
      // may cause NavigationDuplicated err
    })
  }

  async beforeMount() {
    const validationResult = await Promise.all([
      validate(this.$route.params.id, 'applicationId'),
      validate(this.$route.query.key, 'applicationKey'),
    ])
    if (validationResult.every(r => r.valid)) {
      await this.$api.application
        .v1ApplicationsApplicationIdGet(
          this.$route.params.id,
          this.$route.query.key as string,
        )
        .then(res => {
          if (res.status === 200) {
            this.application = ((res as unknown) as AxiosResponse<
              ApplicationDTO
            >).data
            this.particulars.givenName = this.application!.givenName
            this.particulars.familyName = this.application!.familyName
            this.particulars.emailAddress = this.application!.emailAddress
            this.particulars.phoneNumber = this.application!.phoneNumber
            this.particulars.isSubjectToFinance =
              this.formattedFinanceTimeframe !== 0
          } else {
            this.$router.push({
              path: '/error',
              params: {
                statusCode: '204',
                message: 'Application does not exist',
              },
            })
          }
        })
        .then(() => this.loadSigningTimesForStep(this.$route.query.step))
        .catch(err => {
          this.$router.push({
            path: '/error',
            params: { statusCode: '404', message: err.message },
          })
        }),
        (this.loading = false)
    } else {
      this.$router.push({
        path: '/error',
        params: {
          statusCode: '404',
          message: validationResult.reduce(
            (errors, res) => errors.concat(res.errors),
            [] as string[],
          )[0],
        },
      })
    }
  }

  @Watch('isConfirming')
  scrollToTop() {
    window.scroll(0, 0)
  }

  @Watch('$route.query.step')
  async loadSigningTimesForStep(step: string | (string | null)[]) {
    if (step === ApplicationStep.SIGNING_TIME.toString()) {
      await this.loadSigningTimes()
    }
  }

  async loadSigningTimes() {
    if (!this.application?.releaseId) return
    this.loading = true
    await this.$api.release
      .v1ReleasesReleaseIdSigningTimesGet(this.application!.releaseId)
      .then(({ data }) => {
        this.signingTimes = data
        this.loading = false
      })
      .catch(err => {
        this.$router.push({
          path: '/error',
          params: { statusCode: '404', message: err.message },
        })
      })
  }
}
