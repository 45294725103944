























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { AllocationDTO, AllocationType, ApplicationDTO } from '@/api-client/api'
import { formatDistanceToNow, isPast } from 'date-fns'
import { formatSigningTime, parseUtcDate } from '@/modules/date'
import ApplicationStep from '@/types/ApplicationStep'

enum ActionStatus {
  COMPLETEABLE,
  PENDING,
  COMPLETE,
  DISABLED,
}

export const ActionStatusName = {
  [ActionStatus.COMPLETEABLE]: 'Not completed',
  [ActionStatus.PENDING]: 'Not completed',
  [ActionStatus.COMPLETE]: 'Completed',
} as { [key in ActionStatus]: string }

@Component
export default class ApplicationActions extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  application!: ApplicationDTO | null

  // changes from a list of actions to indicator of the current step
  @Prop({
    default: null,
    type: Boolean,
  })
  currentStep!: boolean

  ActionStatus = ActionStatus
  ActionStatusName = ActionStatusName

  get isAcceptanceClosed() {
    const closeDate = this.application?.release?.details?.applicationsCloseDate
    if (!closeDate) return true
    const parsedAcceptanceDate = parseUtcDate(
      this.application!.allocation!.acceptanceEndDateTime!,
    )
    if (isPast(parsedAcceptanceDate)) return true
    return false
  }

  get isOnDemandPurchase() {
    return (
      this.application?.allocation?.allocationType === AllocationType.OnDemand
    )
  }

  get numberOfActions() {
    return this.actions.length
  }

  get actions() {
    return [
      {
        name: 'Fill in particulars',
        status: this.application?.allocation?.particularsComplete
          ? ActionStatus.COMPLETE
          : ActionStatus.COMPLETEABLE,
        buttonText: 'Fill in particulars',
        step: ApplicationStep.PARTICULARS,
      },
      {
        name: 'Book contract appointment',
        status: this.application?.allocation?.signingTimeComplete
          ? ActionStatus.COMPLETE
          : ActionStatus.COMPLETEABLE,
        buttonText: 'Book contract appointment',
        step: ApplicationStep.SIGNING_TIME,
      },
      {
        name: 'Pay holding deposit',
        status: this.application?.allocation?.depositComplete
          ? ActionStatus.COMPLETE
          : this.numActionsComplete === (this.isOnDemandPurchase ? 1 : 2)
          ? ActionStatus.COMPLETEABLE
          : ActionStatus.PENDING,
        buttonText: 'Pay holding deposit',
        step: ApplicationStep.DEPOSIT,
      },
    ]
      .filter(
        action =>
          action.step !== ApplicationStep.SIGNING_TIME ||
          !this.isOnDemandPurchase,
      )
      .map(action =>
        this.isAcceptanceClosed && action.status !== ActionStatus.COMPLETE
          ? {
              ...action,
              status: ActionStatus.DISABLED,
            }
          : action,
      )
      .map(action => {
        let icon = {
          name: '',
          color: '',
        }
        if (
          this.currentStep &&
          action.step.toString() === this.$route.query.step
        ) {
          icon = {
            name: 'circleFilled',
            color: 'accent-aquamarine',
          }
        } else {
          switch (action.status) {
            case ActionStatus.COMPLETEABLE:
              icon = {
                name: 'circleOutlined',
                color: 'grey-silver',
              }
              break
            case ActionStatus.COMPLETE:
              icon = {
                name: 'checkInnerCircleOutlined',
                color: 'accent-aquamarine',
              }
              break
            case ActionStatus.PENDING:
            case ActionStatus.DISABLED:
              icon = {
                name: 'circleDisabled',
                color: 'grey-cultured',
              }
              break
            default:
              break
          }
        }

        let description = ''
        if (
          action.step === ApplicationStep.SIGNING_TIME &&
          action.status === ActionStatus.COMPLETE &&
          this.application?.allocation?.signingTime
        )
          description = `Booked for ${formatSigningTime(
            parseUtcDate(this.application.allocation.signingTime.dateFrom!),
            parseUtcDate(this.application.allocation.signingTime.dateTo!),
          )}`

        return Object.assign(action, {
          icon,
          statusDescription: ActionStatusName[action.status],
          description,
        })
      })
  }

  get countdownString(): string {
    if (this.isAcceptanceClosed) return 'Acceptance closed'
    const parsedAcceptanceDate = parseUtcDate(
      this.application!.allocation!.acceptanceEndDateTime!,
    )
    return formatDistanceToNow(parsedAcceptanceDate)
  }

  get numActionsComplete() {
    if (!this.application?.allocation) return 0

    return ([
      'particularsComplete',
      'signingTimeComplete',
      'depositComplete',
    ] as (keyof AllocationDTO)[]).reduce(
      (acc, key) => acc + (this.application!.allocation![key] ? 1 : 0),
      0,
    )
  }

  goToApplicationStep(step: ApplicationStep) {
    const basePath = `/applications/${this.$route.params.id}`
    const newRoute = {
      path: basePath,
      query: {
        key: this.$route.query.key,
        step: step !== undefined ? step.toString() : undefined,
      },
    }
    this.$router.push(newRoute).catch(() => {
      // may cause NavigationDuplicated err
    })
  }
}
