











































import CopyButton from './CopyButton.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    CopyButton,
  },
})
export default class ApplicationActionConfirmation extends Vue {
  @Prop({
    required: true,
  })
  data!: Record<string, string[][]>
}
