var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"givenNameProvider",attrs:{"mode":"eager","rules":_vm.required ? 'required' : '',"name":"Given name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Given name","autocomplete":"given-name","disabled":!_vm.editable,"outlined":"","error-messages":errors},on:{"change":_vm.emit},model:{value:(_vm.contact.givenName),callback:function ($$v) {_vm.$set(_vm.contact, "givenName", $$v)},expression:"contact.givenName"}})]}}])}),_c('ValidationProvider',{ref:"familyNameProvider",attrs:{"mode":"eager","rules":_vm.required ? 'required' : '',"name":"Family name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Family name","autocomplete":"family-name","disabled":!_vm.editable,"outlined":"","error-messages":errors},on:{"change":_vm.emit},model:{value:(_vm.contact.familyName),callback:function ($$v) {_vm.$set(_vm.contact, "familyName", $$v)},expression:"contact.familyName"}})]}}])}),_c('ValidationProvider',{ref:"emailAddressProvider",attrs:{"mode":"eager","rules":_vm.required ? 'required|email' : 'email',"name":"Email address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"email","placeholder":"Email","type":"email","disabled":!_vm.editable,"outlined":"","error-messages":errors},on:{"change":_vm.emit},model:{value:(_vm.contact.emailAddress),callback:function ($$v) {_vm.$set(_vm.contact, "emailAddress", $$v)},expression:"contact.emailAddress"}})]}}])}),_c('ValidationProvider',{ref:"phoneNumberProvider",attrs:{"mode":"eager","rules":_vm.required ? 'required|phone' : 'phone',"name":"Phone number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"tel","placeholder":"Phone","type":"tel","disabled":!_vm.editable,"outlined":"","error-messages":errors},on:{"change":_vm.emit},model:{value:(_vm.contact.phoneNumber),callback:function ($$v) {_vm.$set(_vm.contact, "phoneNumber", $$v)},expression:"contact.phoneNumber"}})]}}])})],1),_c('v-col',[_c('div',{staticClass:"body-1"},[_vm._v("Is "+_vm._s(_vm.label)+" an Australian resident?")]),_c('v-radio-group',{staticClass:"mb-3",attrs:{"row":""},model:{value:(_vm.contact.isAustralianResident),callback:function ($$v) {_vm.$set(_vm.contact, "isAustralianResident", $$v)},expression:"contact.isAustralianResident"}},[_c('v-radio',{staticClass:"mr-16",attrs:{"label":"Yes","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-sm-4",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.identificationMethods,"label":"Method of identification","data-cy":"Method of identification"},on:{"change":function () {
              _vm.emit()
              _vm.contact.identificationAuthority = ''
            }},model:{value:(_vm.contact.identificationType),callback:function ($$v) {_vm.$set(_vm.contact, "identificationType", $$v)},expression:"contact.identificationType"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.contact.identificationType),expression:"contact.identificationType"}],staticClass:"pl-sm-4",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"Place of issue / issuing authority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(_vm.contact.identificationType === _vm.identificationMethods[0])?_c('v-select',{attrs:{"outlined":"","error-messages":errors,"placeholder":"State","items":_vm.states},on:{"change":_vm.emit},model:{value:(_vm.contact.identificationAuthority),callback:function ($$v) {_vm.$set(_vm.contact, "identificationAuthority", $$v)},expression:"contact.identificationAuthority"}}):_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"placeholder":"Place of issue / issuing authority"},on:{"change":_vm.emit},model:{value:(_vm.contact.identificationAuthority),callback:function ($$v) {_vm.$set(_vm.contact, "identificationAuthority", $$v)},expression:"contact.identificationAuthority"}})]}}])})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.contact.identificationType),expression:"contact.identificationType"}],staticClass:"pr-sm-4",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":((_vm.contact.identificationType === _vm.identificationMethods[0]
                ? 'Licence'
                : 'Passport') + " number"),"rules":("required|" + (_vm.contact.identificationType === _vm.identificationMethods[0]
                ? 'driversLicence'
                : 'passport'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"placeholder":((_vm.contact.identificationType === _vm.identificationMethods[0]
                  ? 'Licence'
                  : 'Passport') + " number")},on:{"change":_vm.emit},model:{value:(_vm.contact.identificationNumber),callback:function ($$v) {_vm.$set(_vm.contact, "identificationNumber", $$v)},expression:"contact.identificationNumber"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }