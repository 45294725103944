




import { parseUtcDate } from '@/modules/date'
import { format } from 'date-fns'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ApplicationCloseDate extends Vue {
  @Prop({ required: true }) date!: Date | string

  get closeDate() {
    const date = this.date instanceof Date ? this.date : parseUtcDate(this.date)
    return format(date, 'h:mma, d LLLL yyyy')
  }
}
