






























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { format } from 'date-fns'
import { parseUtcDate } from '@/modules/date'
import { ReleaseDTO, ApplicationDTO } from '@/api-client'
import ReleaseStatus from '@/types/ReleaseStatus'

@Component
export default class ApplicationDetails extends Vue {
  @Prop({
    required: false,
    type: Object,
  })
  release!: ReleaseDTO
  @Prop({
    required: false,
    type: Object,
  })
  application!: ApplicationDTO
  @Prop({
    type: Boolean,
  })
  sellerLogo!: boolean
  @Prop({
    type: Boolean,
  })
  headers!: boolean
  @Prop({
    type: Boolean,
  })
  lots!: boolean

  get numLots() {
    return this.application
      ? this.application.preferences.length
      : this._release.lots.length
  }

  get _release() {
    return this.release ?? this.application.release
  }

  get showApplicationInformation() {
    return (
      !this.application &&
      this.release.status !== ReleaseStatus.OnDemandPurchasing
    )
  }

  get closeDate() {
    return this._release
      ? format(
          parseUtcDate(this._release.details!.applicationsCloseDate),
          'h:mma',
        ) +
          format(
            parseUtcDate(this._release.details!.applicationsCloseDate),
            ', d LLLL yyyy',
          )
      : ''
  }
}
