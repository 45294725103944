












































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { ContactDTO } from '@/api-client'
import { identificationMethodsList } from '@/types/IdentificationMethod'
import { states } from '@/modules/config'
import { Validatable } from '@/types/validation'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class ContactForm extends Vue {
  @Prop({
    default: true,
    type: Boolean,
  })
  required!: boolean
  @Prop({
    type: Object,
  })
  value!: ContactDTO
  @Prop({
    type: String,
  })
  label!: string
  @Prop({
    default: true,
    type: Boolean,
    required: false,
  })
  editable!: boolean

  identificationMethods = identificationMethodsList

  contact: ContactDTO = {
    givenName: '',
    familyName: '',
    emailAddress: '',
    phoneNumber: '',
    isAustralianResident: true,
    identificationType: this.identificationMethods[0],
    identificationNumber: '',
    identificationAuthority: '',
  }

  get states() {
    return states.map(s => s.postal)
  }

  emit() {
    this.$emit('input', { ...this.value, ...this.contact })
  }

  mounted() {
    if (this.value) {
      this.contact.givenName = this.value.givenName
      this.contact.familyName = this.value.familyName
      this.contact.emailAddress = this.value.emailAddress
      this.contact.phoneNumber = this.value.phoneNumber

      this.$nextTick(() => {
        // This is required so that validation is triggered for the fields that we automatically populated.
        Object.values(
          (this.$refs as unknown) as Record<string, Validatable>,
        ).map(value => {
          if (value.validate) {
            value.validate()
          }
        })
      })
    }
  }
}
