enum ApplicationStep {
  OVERVIEW,
  ACCEPT,
  REJECT,
  PARTICULARS,
  SIGNING_TIME,
  DEPOSIT,
}

export default ApplicationStep
